<template>
  <div>
    <v-row class="fill-height breadcrumb pt-4" justify="center" align="center">
      <h1 class="font-weight-bold text-h3 white--text">CONTACT US</h1>
    </v-row>
    <v-container>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-row class="mx-auto mt-6">
        <v-col
          class="align-self-center"
          cols="12"
          sm="12"
          md="6"
          order="3"
          order-sm="2"
        >
          <v-card flat class="pa-5 text-center" height="450px">
            <h2 class>Contact Us:</h2>

            <div class="pt-15">
              <span class="d-block" title="Kinplus Technologies"
                >Kinplus Technologies</span
              >
              <span class="d-block" title="Adress"
                >2nd Floor, Christore Building,</span
              >
              <span class="d-block" title="Adress"
                >Opp. Crunchies Restaurant</span
              >
              <span class="d-block" title="Adress">Similoluwa, Ado-Ekiti,</span>
              <span class="d-block" title="Location"
                >Ekiti State, Nigeria.</span
              >
              <span class="d-block">Email: help@kinplusgroup.com</span>
              <span title="Kinplus Technologies, contact line."
                >Phone: +2347075199782, +2348116400858</span
              >
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="6" lg="6" order="1">
          <v-card shaped>
            <v-container>
              <h3>Get More Information</h3>
              <v-form v-model="valid" lazy-validation @submit.prevent>
                <v-text-field
                  label="Name"
                  v-model.trim="dform.fullname"
                  outlined
                  dense
                  required
                  :rules="nameRules"
                  prepend-inner-icon="mdi-account-circle"
                ></v-text-field>
                <v-text-field
                  label="E-mail"
                  v-model.trim="dform.email"
                  :rules="emailRules"
                  outlined
                  dense
                  required
                  prepend-inner-icon="mdi-email"
                ></v-text-field>
                <v-text-field
                  label="Phone Number"
                  v-model.trim="dform.phone"
                  outlined
                  dense
                  :counter="15"
                  :rules="phoneRules"
                  hint="Enter only numbers"
                  prepend-inner-icon="mdi-cellphone"
                  required
                ></v-text-field>
                <v-text-field
                  label="Subject"
                  v-model.trim="dform.subject"
                  outlined
                  dense
                  :rules="messageRules"
                  required
                  prepend-inner-icon="mdi-comment-text"
                ></v-text-field>
                <v-textarea
                  rows="2"
                  label="Message"
                  v-model.trim="dform.message"
                  outlined
                  :rules="messageRules"
                  dense
                  required
                  prepend-inner-icon="mdi-comment-text"
                ></v-textarea>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="!valid"
                    color="info"
                    @click="submitForm"
                    :loading="loading"
                    >Submit</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" order="3">
          <iframe
            class="mt-0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3954.3885110487495!2d5.21967881405219!3d7.641302311280639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1047fb30f1253287%3A0x76e86be31ccafde8!2sKinplus%20Technologies!5e0!3m2!1sen!2sng!4v1660307614907!5m2!1sen!2sng"
            width="100%"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="dark" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { snackMixin, rulesMixin } from "@/mixins";
import { apiClient } from "@/services";
export default {
  name: "Contact",

  mixins: [snackMixin, rulesMixin],
  data() {
    return {
      dform: this.initializeForm(),
      valid: false,

      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "Contact Us",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    isValid() {
      const reqf = ["fullname", "phone", "subject", "email", "message"];
      return reqf.every((v) => !!this.dform[v]);
    },
  },
  methods: {
    submitForm() {
      this.loading = true;
      apiClient
        .post("/contacts", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.loading = false;
            this.displayMsg(
              "Thanks for contacting us, we will get back to you"
            );
            this.dform = this.initializeForm();
          }
        })
        .catch((error) => this.displayMsg(error.message, "error"));
    },
    initializeForm: () => ({
      fullname: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    }),
  },
};
</script>
